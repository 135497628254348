<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-email-dispatch', params: { accountId: this.$route.params.accountId } }">Email Dispatches</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ emailDispatch.mailbox }}@{{ emailDispatch.domain }}</h1>
                <p class="text-caption text-center">Email dispatch overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 mt-2 mx-5" v-if="emailDispatch">
                <v-col cols="12">
                    <p class="text-caption grey--text text-end my-0">Dispatch {{ emailDispatch.id }}</p>
                    <v-card>
                        <v-toolbar color="teal darken-2" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Email dispatch
                            <span v-if="emailDispatchStatus && emailDispatchStatus.status">
                                ({{ emailDispatchStatus.status }})
                            </span>
                        </v-toolbar-title>
                        <template v-if="!emailDispatchStatus || emailDispatchStatus.status === 'new'">
                            <v-spacer/>
                            <!-- <v-btn color="white green--text" @click="startDispatch">
                                <font-awesome-icon :icon="['fas', 'paper-plane']"/>
                                Start now
                            </v-btn> -->
                            <v-menu offset-y open-on-click open-on-hover close-delay="100">
                                <template #activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" color="white">
                                        <font-awesome-icon :icon="['fas', 'paper-plane']"/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item @click="confirmStartEmailDispatchDeliveryDialog = true">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'play']" class="teal--text text--darken-2" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Start now...
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="editEmailDispatchDeliveryDateDialog = true">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'calendar-edit']" class="teal--text text--darken-2" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Reschedule...
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <v-progress-linear
                            :active="emailDispatchStatus && emailDispatchStatus.status === 'started'"
                            :value="emailDispatchProgress"
                            absolute
                            bottom
                            color="teal darken-2"
                        ></v-progress-linear>
                        </v-toolbar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-2">Email Template</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-email-template', params: { accountId: this.$route.params.accountId, emailTemplateId: emailDispatch.email_template_id } }">
                                {{ emailDispatch.email_template_label }}
                                </router-link>
                                <!-- <v-btn icon color="teal darken-2" @click="editEmailDispatchFrom">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn> -->
                            </p>
                            <p class="mt-4 mb-0 pb-0" v-if="emailTemplate && emailTemplate.email_origin_id && emailOrigin">
                                <!-- From: {{ emailTemplate.email_origin_id }} -->
                                From: {{ emailOrigin.label }} &lt;{{ emailOrigin.mailbox }}@{{ emailOrigin.domain }}&gt;
                                <span class="ml-2">
                                <template v-if="emailOriginDomain && emailOriginDomain.status === 'verified'">
                                    <font-awesome-icon :icon="['fas', 'check-circle']" color="teal darken-2"/>
                                    Verified domain
                                </template>
                                <template v-if="!emailOriginDomain || emailOriginDomain.status !== 'verified'">
                                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" color="amber"/>
                                    Not verified
                                </template>
                                </span>
                            </p>
                            <p class="mb-0 pb-0" v-if="emailTemplate && emailTemplate.email_replyto_id && emailReplyto">
                                <!-- Reply-To: {{ emailTemplate.email_replyto_id }} -->
                                Reply-To: {{ emailReplyto.label }} &lt;{{ emailReplyto.mailbox }}@{{ emailReplyto.domain }}&gt;
                                <span class="ml-2">
                                <template v-if="emailReplytoDomain && emailReplytoDomain.status === 'verified'">
                                    <font-awesome-icon :icon="['fas', 'check-circle']" color="teal darken-2"/>
                                    Verified domain
                                </template>
                                <template v-if="!emailReplytoDomain || emailReplytoDomain.status !== 'verified'">
                                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" color="amber"/>
                                    Not verified
                                </template>
                                <!-- TODO: we should also check if the actual email address itself is verified, that there's no typo that would prevent user from receiving mail there -->
                                </span>
                            </p>
                            <p class="mb-0 pb-0" v-if="emailTemplate">
                                Subject: {{ emailTemplate.subject }}
                            </p>
                            <p class="text-overline mb-0 mt-8">Email Audience</p>
                            <p class="mb-0 pb-0" v-if="emailAudience">
                                <router-link :to="{ name: 'account-edit-email-audience', params: { accountId: this.$route.params.accountId, emailAudienceId: emailAudience.id } }">
                                {{ emailAudience.label }}
                                </router-link>
                                <!-- <v-btn icon color="teal darken-2" @click="editEmailDispatchFrom">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn> -->
                            </p>
                            <p class="mt-4 mb-0 pb-0" v-if="emailAudience && emailAudience.count">
                                There are {{ emailAudience.count }} contacts in this email audience.
                            </p>
                            <p class="mb-0 pb-0" v-if="emailContact">
                                <router-link :to="{ name: 'account-edit-email-contact', params: { accountId: this.$route.params.accountId, emailContactId: emailContact.id } }">
                                {{ emailContact.email }}
                                </router-link>
                                <!-- <v-btn icon color="teal darken-2" @click="editEmailDispatchFrom">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn> -->
                            </p>

                            <p class="text-overline mb-0 mt-8">Scheduled for delivery</p>
                            <p class="mb-0 pb-0">
                                {{ emailDispatchNotBeforeDisplay }}
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Email Dispatch ID</p>
                            <p class="mb-0 pb-0">
                                {{ emailDispatch.id }}
                            </p> -->

                            <!--
                            <p class="text-overline mb-0 mt-8">Status</p>
                            <p class="mb-0 pb-0" v-if="emailDispatchStatus">
                                {{ emailDispatchStatus.status }}
                            </p>
                            <p class="mb-0 pb-0" v-if="emailDispatchStatus && emailDispatchStatus.status === 'started'">
                                {{ emailDispatchStatusQueueItemCountDisplay }}
                            </p>
                            <p class="mb-0 pb-0" v-if="!emailDispatchStatus || emailDispatchStatus.status === 'new'">
                                <v-btn color="green white--text" @click="startDispatch">
                                    <font-awesome-icon :icon="['fas', 'paper-plane']"/>
                                    Start now
                                </v-btn>
                            </p>
                            <p class="mb-0 pb-0" v-if="emailDispatchStatus && emailDispatchStatus.status === 'started' && typeof emailDispatchStatusQueueItemCount === 'number' && emailDispatchStatusQueueItemCount === 0">
                                <v-btn color="green white--text" @click="checkDispatch">
                                    <font-awesome-icon :icon="['far', 'clipboard-list-check']"/>
                                    Check progress
                                </v-btn>
                            </p>
                            -->
                            <template v-if="emailDispatchReport">
                            <p class="text-overline mb-0 mt-8">Status</p>
                            <p class="mb-0 pb-0">
                                <!-- <router-link :to="{ name: 'account-search-email-contact-dispatch-event', params: { emailDispatchId: this.$route.params.emailDispatchId } }"> -->
                                {{ emailDispatchReport.eligibleContactCount }} eligible
                                <!-- </router-link> -->
                                /
                                <!-- <router-link :to="{ name: 'account-search-email-contact-dispatch-event', params: { emailDispatchId: this.$route.params.emailDispatchId } }"> -->
                                {{ emailDispatchReport.allContactCount }} contacts
                                <!-- </router-link> -->
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-email-contact-dispatch-event', params: { accountId: this.$route.params.accountId }, query: { emailDispatchId: this.$route.params.emailDispatchId, status: 'sent' } }">
                                {{ emailDispatchReport.sentCount }} sent
                                </router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-email-contact-dispatch-event', params: { accountId: this.$route.params.accountId }, query: { emailDispatchId: this.$route.params.emailDispatchId, status: 'error' } }">
                                {{ emailDispatchReport.errorCount }} errors
                                </router-link>
                            </p>
                            </template>
                        </v-card-text>
                    </v-card>
                    <!-- <p class="text-overline mb-0 mt-8">Usage</p>
                    <p class="mb-0 pb-0">
                        {{ usage }}
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn icon @click="checkEmailDispatchUsage" v-bind="attrs" v-on="on">
                                    <font-awesome-icon :icon="['far', 'sync-alt']"/>
                                </v-btn>
                            </template>
                            Refresh
                        </v-tooltip>
                    </p> -->

                    <!-- <template v-if="emailDispatch.website_id">
                    <p class="text-overline mb-0 mt-8">Website</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: emailDispatch.website_id } }">
                            <span v-if="website">{{ website.label }}</span>
                            <span v-if="!website">Website</span>
                        </router-link>
                    </p>
                    </template> -->

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, emailDispatchId: this.$route.params.emailDispatchId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Email Dispatch Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.emailDispatch, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 px-10" v-if="emailDispatch">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                </v-col>
            </v-row> -->
            <v-dialog v-model="editEmailDispatchReplyToDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email reply-to address</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The email reply-to address is where responses will go when the user hits
                            the "reply" button in their email application.
                            You can change it at any time, but it will only affect future emails sent.
                            You should keep an prior addresses used to collect replies for a while
                            (until you see that it's not getting any replies for a period of time, such
                            as 90 days) before you delete the mailbox.
                        </p>
                        <v-text-field v-model="editableEmailDispatchReplyTo" label="Email reply-to address"></v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailDispatchReplyTo" :disabled="!isEditEmailDispatchReplyToComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailDispatchReplyToDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailDispatchDeliveryDateDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="teal darken-2">
                    <v-toolbar-title class="white--text">Edit the scheduled delivery date</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="mt-6 px-2 py-2">
                    <v-row no-gutters>
                    <v-col cols="12" sm="6">
                        <!-- <v-text-field v-model="editableEmailDispatchDeliveryDate" label="New delivery date"></v-text-field> -->
                        <!-- <v-date-picker v-model="editableEmailDispatchDeliveryDate" label="New delivery date"></v-date-picker> -->
                        <v-menu
                        v-model="editableEmailDispatchDeliveryDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <!--  -->
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            label="Start date"
                            :value="editableEmailDispatchDeliveryDate"
                            persistent-hint
                            readonly
                            outlined
                            dense
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            >
                                <template #prepend-inner>
                                    <font-awesome-icon :icon="['far', 'calendar-alt']" fixed-width class="teal--text text--darken-2"/>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editableEmailDispatchDeliveryDate"
                            no-title
                            @input="editableEmailDispatchDeliveryDateMenu = false"
                        ></v-date-picker>
                        </v-menu>

                    </v-col>
                    <v-col cols="12" sm="6">
                        <!-- <v-text-field v-model="editableEmailDispatchDeliveryDate" label="New delivery date"></v-text-field> -->
                        <!-- <v-date-picker v-model="editableEmailDispatchDeliveryDate" label="New delivery date"></v-date-picker> -->
                        <v-menu
                        v-model="editableEmailDispatchDeliveryTimeMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <!--  -->
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            label="Start time"
                            :value="editableEmailDispatchDeliveryTime"
                            persistent-hint
                            readonly
                            outlined
                            dense
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            >
                                <template #prepend-inner>
                                    <font-awesome-icon :icon="['far', 'clock']" fixed-width class="teal--text text--darken-2"/>
                                </template>
                            </v-text-field>
                        </template>
                        <v-time-picker
                            v-model="editableEmailDispatchDeliveryTime"
                            ampm-in-title
                            format="ampm"
                            scrollable
                            @click:minute="editableEmailDispatchDeliveryTimeMenu = false"
                        ></v-time-picker>
                        </v-menu>

                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="teal darken-2 white--text" @click="saveEditEmailDispatchDeliveryDate" :disabled="!isEditEmailDispatchDeliveryDateComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailDispatchDeliveryDateDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="confirmStartEmailDispatchDeliveryDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="teal darken-2">
                    <v-toolbar-title class="white--text">Start dispatch now?</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <!-- <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <v-text-field v-model="editableEmailDispatchDeliveryDate" label="New delivery date"></v-text-field>
                    </v-col>
                    </v-row>
                </v-form> -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="teal darken-2 white--text" @click="startDispatch">
                        <span>Start dispatch</span>
                    </v-btn>
                    <v-btn text color="grey" @click="confirmStartEmailDispatchDeliveryDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-btn:not(.v-btn--icon) .v-btn__content svg {
    margin-right: 8px;
}
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState } from 'vuex';
// import EmailDispatchList from '@/components/account-dashboard/EmailDispatchList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';
import { toMillis } from '@libertyio/time-util-js';

export default {
    components: {
        // EmailDispatchList,
    },
    data: () => ({
        affectedEmailTemplateList: [],

        error: null,
        account: null,
        emailDispatch: null,
        emailDispatchStatus: null,
        emailDispatchStatusQueueItemCount: null,
        emailDispatchProgress: null, // [0,100] controls progress bar
        emailDispatchReport: null,
        emailTemplate: null,
        emailAudience: null,
        emailContact: null,
        emailOrigin: null,
        emailOriginDomain: null,
        emailReplyto: null,
        emailReplytoDomain: null,
        invite: null,
        // TODO: remove these that aren't being used
        editEmailDispatchFromDialog: false,
        editEmailDispatchDisplayNameDialog: false,
        editableEmailDispatchDisplayName: null,
        editEmailDispatchReplyToDialog: false,
        editableEmailDispatchReplyTo: null,
        editableEmailDispatchFrom: null,

        // edit delivery date
        editEmailDispatchDeliveryDateDialog: null,
        editableEmailDispatchDeliveryDate: null,
        editableEmailDispatchDeliveryDateMenu: null,
        editableEmailDispatchDeliveryTime: null,
        editableEmailDispatchDeliveryTimeMenu: null,

        // confirm start delivery dialog
        confirmStartEmailDispatchDeliveryDialog: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.emailDispatch !== null;
        },
        isEditEmailDispatchFromComplete() {
            return typeof this.editableEmailDispatchFrom === 'string' && this.editableEmailDispatchFrom.trim().length > 0;
        },
        isEditEmailDispatchDisplayNameComplete() {
            return typeof this.editableEmailDispatchDisplayName === 'string' && this.editableEmailDispatchDisplayName.trim().length > 0;
        },
        isEditEmailDispatchReplyToComplete() {
            return typeof this.editableEmailDispatchReplyTo === 'string' && this.editableEmailDispatchReplyTo.trim().length > 0;
        },
        emailDispatchNotBeforeDisplay() {
            try {
                if (typeof this.emailDispatch?.not_before === 'number' && this.emailDispatch.not_before > 0) {
                    const date = new Date(this.emailDispatch.not_before);
                    return date.toString();
                }
                return 'Unknown';
            } catch (err) {
                console.log(`failed to parse emailDispatch not_before date: ${this.emailDispatch.not_before}`);
                return 'Unknown';
            }
        },
        emailDispatchStatusQueueItemCountDisplay() {
            if (typeof this.emailDispatchStatusQueueItemCount === 'number') {
                if (this.emailDispatchStatusQueueItemCount === 0) {
                    return 'There are no items in the queue';
                }
                if (this.emailDispatchStatusQueueItemCount === 1) {
                    return 'There is 1 item in the queue';
                }
                return `There are ${this.emailDispatchStatusQueueItemCount} items in the queue`;
            }
            return 'Dispatch queue information is not available';
        },
        isEditEmailDispatchDeliveryDateComplete() {
            return this.editableEmailDispatchDeliveryDate;
        },
    },
    watch: {
        editEmailDispatchDeliveryDateDialog(newValue) {
            if (newValue) {
                // initialize date and time pickers with current values
                if (typeof this.emailDispatch?.not_before === 'number' && this.emailDispatch.not_before > 0) {
                    // server & browser timestamps are in UTC, but javascript doesn't have an option to format
                    // the date in local timezone, so we cheat by moving the actual timestamp using our timezone
                    // offset, so that the resulting format string shows the date and time in local time
                    // but with an incorrect 'Z' timezone, which we strip out anyway.
                    const tzOffset = (new Date()).getTimezoneOffset() * 60000;
                    const date = new Date(this.emailDispatch.not_before - tzOffset);
                    const datetime = date.toISOString(); // for example 2011-10-05T14:48:00.000Z
                    const [dateText, remainder] = datetime.split('T');
                    const [hours, minutes] = remainder.split(':'); // discard seconds, millis, and the 'Z' timezone indicator (which is actually incorrect anyway, see note above)
                    this.editableEmailDispatchDeliveryDate = dateText;
                    this.editableEmailDispatchDeliveryTime = `${hours}:${minutes}`;
                }
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadEmailDispatch() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailDispatch: true });
                const response = await this.$client.account(this.$route.params.accountId).emailDispatch.get(this.$route.params.emailDispatchId);
                console.log(`loadEmailDispatch: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailDispatch = response;
                    if (this.emailDispatch.email_template_id) {
                        this.loadEmailTemplate();
                    }
                    if (this.emailDispatch.target_type === 'email_audience') {
                        this.loadEmailAudience();
                    }
                    if (this.emailDispatch.target_type === 'email_contact') {
                        this.loadEmailContact();
                    }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email dispatch');
                }
            } catch (err) {
                console.error('failed to load email dispatch', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailDispatch: false });
            }
        },
        async loadEmailDispatchStatus() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailDispatchStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).emailDispatchStatus.get(this.$route.params.emailDispatchId);
                console.log(`loadEmailDispatchStatus: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailDispatchStatus = response;
                    if (['started', 'finished'].includes(response.status)) {
                        this.checkDispatch();
                    }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email dispatch');
                }
            } catch (err) {
                console.error('failed to load email dispatch', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailDispatchStatus: false });
            }
        },
        /*
        async loadEmailDispatchQueueItemCount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailDispatchQueueItemCount: true });
                const response = await this.$client.account(this.$route.params.accountId).emailDispatchQueueItem.search({ email_dispatch_id: this.$route.params.emailDispatchId, count: true });
                console.log(`loadEmailDispatchQueueItemCount: response ${JSON.stringify(response)}`);
                if (response && typeof response.count === 'number') {
                    this.emailDispatchStatusQueueItemCount = response.count;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email dispatch');
                }
            } catch (err) {
                console.error('failed to load email dispatch', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailDispatchQueueItemCount: false });
            }
        },
        */
        async loadEmailTemplate() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailTemplate: true });
                const response = await this.$client.account(this.$route.params.accountId).emailTemplate.get(this.emailDispatch.email_template_id);
                console.log(`loadEmailTemplate: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailTemplate = response;
                    if (this.emailTemplate.email_origin_id) {
                        this.loadEmailOrigin();
                    }
                    if (this.emailTemplate.email_replyto_id) {
                        this.loadEmailReplyto();
                    }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email template');
                }
            } catch (err) {
                console.error('failed to load email template', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailTemplate: false });
            }
        },
        async loadEmailOrigin() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailOrigin: true });
                const response = await this.$client.account(this.$route.params.accountId).emailOrigin.get(this.emailTemplate.email_origin_id);
                console.log(`loadEmailOrigin: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailOrigin = response;
                    this.emailOriginDomain = await this.loadDomainByName(response.domain);
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email origin');
                }
            } catch (err) {
                console.error('failed to load email origin', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailOrigin: false });
            }
        },
        async loadEmailReplyto() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailReplyto: true });
                const response = await this.$client.account(this.$route.params.accountId).emailReplyto.get(this.emailTemplate.email_replyto_id);
                console.log(`loadEmailReplyto: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailReplyto = response;
                    this.emailReplytoDomain = await this.loadDomainByName(response.domain);
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email reply-to');
                }
            } catch (err) {
                console.error('failed to load email reply-to', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailReplyto: false });
            }
        },
        async loadEmailAudience() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailAudience: true });
                const response = await this.$client.account(this.$route.params.accountId).emailAudience.get(this.emailDispatch.target_id);
                console.log(`loadEmailAudience: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailAudience = response;
                    this.loadEmailAudienceContactCount();
                    // TODO: load audience contact list to show who exactly will be getting the email, and start comparing email template placeholders to available data
                    // if (this.emailDispatch.email_template_id) {
                    //     this.loadEmailTemplate();
                    // }
                    // if (this.emailDispatch.email_audience_id) {
                    //     this.loadEmailAudience();
                    // }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email audience');
                }
            } catch (err) {
                console.error('failed to load email audience', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailAudience: false });
            }
        },
        async loadEmailAudienceContactCount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailAudienceContactCount: true });
                const response = await this.$client.account(this.$route.params.accountId).emailAudienceContact.check({ email_audience_id: this.emailAudience.id }, { item: 'count' });
                console.log(`loadEmailAudienceContactCount: response ${JSON.stringify(response)}`);
                if (response && response.count) {
                    this.$set(this.emailAudience, 'count', response.count);
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email audience');
                }
            } catch (err) {
                console.error('failed to load email audience', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailAudienceContactCount: false });
            }
        },
        async loadEmailContact() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailContact: true });
                const response = await this.$client.account(this.$route.params.accountId).emailContact.get(this.emailDispatch.target_id);
                console.log(`loadEmailContact: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailContact = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email audience');
                }
            } catch (err) {
                console.error('failed to load email audience', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailContact: false });
            }
        },
        // unlike the other functions, this one returns the response so that we can reuse it for both origin domain and replyto domain
        async loadDomainByName(name) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.get({ name });
                console.log(`loadDomain: response ${JSON.stringify(response)}`);
                if (response) {
                    // TODO: do we need to load status separately?
                    return response;
                }
                console.error('failed to load domain');
                return null;
            } catch (err) {
                console.error('failed to load domain', err);
                return null;
            } finally {
                this.$store.commit('loading', { loadDomain: false });
            }
        },
        async startDispatch() {
            try {
                this.error = false;
                this.$store.commit('loading', { startDispatch: true });
                const response = await this.$client.account(this.$route.params.accountId).emailDispatch.check(this.$route.params.emailDispatchId, { item: 'start' });
                console.log(`startDispatch: response ${JSON.stringify(response)}`);
                if (response) {
                    if (['ready', 'started'].includes(response.status)) {
                        this.$bus.$emit('snackbar', { type: 'success', headline: 'Started dispatch' });
                        setTimeout(() => { this.checkDispatch(); }, toMillis({ seconds: 5 }));
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to start dispatch' });
                    }
                }
            } catch (err) {
                console.error('failed to start dispatch', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to start dispatch' });
            } finally {
                this.$store.commit('loading', { startDispatch: false });
            }
        },
        async checkDispatch() {
            try {
                this.error = false;
                this.$store.commit('loading', { startDispatch: true });
                const response = await this.$client.account(this.$route.params.accountId).emailDispatch.check(this.$route.params.emailDispatchId, { item: 'progress' });
                console.log(`startDispatch: response ${JSON.stringify(response)}`);
                if (response) {
                    // dispatch queue progress
                    this.$set(this.emailDispatchStatus, 'status', response.status);
                    if (typeof response.progress === 'number' && response.max === 'number') {
                        this.emailDispatchProgress = Math.floor((response.progress * 100) / response.max);
                    } else {
                        this.emailDispatchProgress = null;
                    }
                    // delivery report
                    this.emailDispatchReport = {
                        allContactCount: response.allContactCount,
                        eligibleContactCount: response.eligibleContactCount,
                        sentCount: response.sentCount,
                        errorCount: response.errorCount,
                    };
                    // if dispatch not yet finished, check again in a few seconds
                    if (response.status === 'started') {
                        setTimeout(() => { this.checkDispatch(); }, toMillis({ seconds: 5 }));
                    }
                }
            } catch (err) {
                console.error('failed to check dispatch', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check progress' });
            } finally {
                this.$store.commit('loading', { startDispatch: false });
            }
        },
        async save(itemAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditEmailDispatch: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).emailDispatch.edit({ id: this.$route.params.emailDispatchId }, itemAttr);
                console.log(`saveEditEmailDispatch: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email dispatch' });
                return false;
            } catch (err) {
                console.error('failed to edit email dispatch', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email dispatch' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditEmailDispatch: false });
            }
        },
        editEmailDispatchFrom() {
            this.editEmailDispatchFromDialog = true;
            this.editableEmailDispatchFrom = this.emailDispatch.from;
        },
        editEmailDispatchDisplayName() {
            this.editEmailDispatchDisplayNameDialog = true;
            this.editableEmailDispatchDisplayName = this.emailDispatch.display_name;
        },
        editEmailDispatchReplyTo() {
            this.editEmailDispatchReplyToDialog = true;
            this.editableEmailDispatchReplyTo = this.emailDispatch.reply_to;
        },
        async saveEditEmailDispatchFrom() {
            const isEdited = await this.save({ from: this.editableEmailDispatchFrom });
            this.editEmailDispatchFromDialog = false;
            if (isEdited) {
                this.$set(this.emailDispatch, 'from', this.editableEmailDispatchFrom);
            }
        },
        async saveEditEmailDispatchDisplayName() {
            const isEdited = await this.save({ display_name: this.editableEmailDispatchDisplayName });
            this.editEmailDispatchDisplayNameDialog = false;
            if (isEdited) {
                this.$set(this.emailDispatch, 'display_name', this.editableEmailDispatchDisplayName);
            }
        },
        async saveEditEmailDispatchReplyTo() {
            const isEdited = await this.save({ reply_to: this.editableEmailDispatchReplyTo });
            this.editEmailDispatchReplyToDialog = false;
            if (isEdited) {
                this.$set(this.emailDispatch, 'reply_to', this.editableEmailDispatchReplyTo);
            }
        },
        async saveEditEmailDispatchDeliveryDate() {
            if (!this.editableEmailDispatchDeliveryDate || !this.editableEmailDispatchDeliveryTime) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Select date and time for delivery' });
                return;
            }
            if (this.emailDispatchStatus.status !== 'new') {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot reschedule a dispatch that already started' });
                return;
            }

            // date input is without timezone, and javascript assumes local time, which is what we want;
            // TODO: allow user to choose timezone, then we can adjust the timestamp for that
            // const tzOffset = (new Date()).getTimezoneOffset() * 60000;

            const parts = [];
            parts.push(this.editableEmailDispatchDeliveryDate);
            parts.push(this.editableEmailDispatchDeliveryTime);

            const iso8601 = parts.join('T');
            const dateObject = new Date(iso8601);
            const ts = dateObject.getTime(); // + tzOffset;

            const now = Date.now();
            if (now > ts) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Choose a date in the future' });
                return;
            }

            const isEdited = await this.save({ not_before: ts });
            this.editEmailDispatchDeliveryDateDialog = false;
            if (isEdited) {
                this.$set(this.emailDispatch, 'not_before', ts);
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadEmailDispatch();
        this.loadEmailDispatchStatus();
        // this.loadEmailDispatchQueueItemCount();
    },
};
</script>
